import React from 'react';

import reportIcon from '../../assets/media/how-it-works-report-icon.svg';
import chainIcon from '../../assets/media/how-it-works-chain-icon.svg';
import lossIcon from '../../assets/media/how-it-works-loss-icon.svg';
import aiIcon from '../../assets/media/how-it-works-ai-icon.svg';
import analyticsIcon from '../../assets/media/how-it-works-analytics-icon.svg';
import blurImg from '../../assets/media/how-it-works-blur-img.png';
import TaxCalculatorSlider from '../TaxCalculatorSlider/TaxCalculatorSlider';

const HowItWorks = () => (
    <section id="how-it-works" className="how-it-works-section">
        <div className="content-wrap">
            <div className="grid-wrap desktop-only">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className="text-wrap">
                <h2 className="animation">Our Tax Tools</h2>

                <div className="items-wrap">
                    <div className="item animation">
                        <img src={reportIcon} alt="Icon"/>
                        <h3>Detailed<br/> Reports</h3>
                        <p>Get complete insights on your crypto tax deductions</p>
                    </div>
                    <div className="item animation">
                        <img src={chainIcon} alt="Icon"/>
                        <h3>Multi-Chain<br/> Support</h3>
                        <p>Works with various cryptocurrencies like Ethereum, Bitcoin, and more.</p>
                    </div>
                    <div className="item animation">
                        <img src={lossIcon} alt="Icon"/>
                        <h3>Spot<br/> Losses</h3>
                        <p>Identify non-profitable tokens and include them in tax deductions.</p>
                    </div>
                    <div className="item animation">
                        <img src={aiIcon} alt="Icon"/>
                        <h3>AI-Driven<br/> Tax Strategy</h3>
                        <p>AI-driven advice for better tax decisions.</p>
                    </div>
                    <div className="item animation">
                        <img src={analyticsIcon} alt="Icon"/>
                        <h3>Market <br/> Analysis</h3>
                        <p>Stay updated with the latest trends for smart decisions.</p>
                    </div>
                    <div className="item animation">
                        <img src={analyticsIcon} alt="Icon"/>
                        <h3>AI & Machine <br/> Learning</h3>
                        <p>Powered by AI & ML bringing detailed information about everything crypto.</p>
                    </div>
                </div>
            </div>
            <div className="blur-wrap">
                <img src={blurImg} alt="Blur"/>
            </div>
        </div>
    </section>
);

export default HowItWorks;
