import React from 'react';

// import aboutImg from '../../assets/media/about-img.png';
import aboutImg from '../../assets/media/img2.png';


const About = () => (
    <section id="about" className="about-section">
        <div className="content-wrap">
            <div className="img-wrap">
                <img src={aboutImg} alt="About"/>
            </div>
            <div className="text-wrap">
                <div className="title-wrap animation">
                    <h2>Turn losses into wins with AITAXBOT</h2>
                </div>
                <div className="description-wrap animation">
            
                    <p>Navigate crypto taxes with confidence. Altaxbot analyzes your transactions to uncover deductions, minimize losses, 
                        and enhance savings on everything from gas fees to token swaps. It's your all-in-one tax assistant for a 
                        clearer financial picture.</p>
                </div>
                <div className="links-wrap animation">
                <a href="https://t.me/aitaxbot" className="card" target="_blank" rel="noopener noreferrer">
                        <h4>Try AITAXBOT</h4>
                        <h5>+</h5>
                    </a>
                    <a href="https://docs.aitax.bot" className="card card-dark" target="_blank" rel="noopener noreferrer">
                        <h4>Learn More</h4>
                        <h5>+</h5>
                    </a>
                </div>
            </div>
        </div>
    </section>
);

export default About;
