import React from 'react';

import telegramIcon from '../../assets/media/telegram-icon.svg';
import xIcon from '../../assets/media/x-icon.svg';
import blurImg from '../../assets/media/contact-blur-img.png';
import blurMobileImg from '../../assets/media/contact-blur-mobile-img.png';
import uniswapIcon from '../../assets/media/uniswap-icon.svg';
import dextoolsIcon from '../../assets/media/dextools-icon.svg';

const ContactUs = ({ isLgScreen }) => (
    <section id="contact-us" className="contact-us-section">
        <div className="content-wrap">
            <div className="grid-wrap desktop-only">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className="title-wrap animation">
                <h2>Save Now</h2>
            </div>
            {/* <div className="socials-wrap animation">
            <a href="https://t.me/aitaxbotportal" target="_blank" rel="noopener noreferrer">
                    <img src={telegramIcon} alt="Icon"/>
                    <h3>Telegram</h3>
                </a>
                <a href="https://x.com/aitaxbot" target="_blank" rel="noopener noreferrer">
                    <img src={xIcon} alt="Icon"/>
                    <h3>Twitter/X</h3>
                </a>
                <a href="https://t.me/aitaxbot" target="_blank" rel="noopener noreferrer">
                    <img src={telegramIcon} alt="Icon"/>
                    <h3>Telegram Bot</h3>
                </a>
            </div> */}
            <div className="text-wrap">
                <div className="lines-wrap">
                    <div className="line">
                        <h4>Simplify tax management and maximize deductions effortlessly.</h4>
                    </div>
                    {/* <div className="line line-with-links">
                        <div className="links-wrap">
                            <a href="#">
                                <img src={uniswapIcon} alt="Uniswap"/>
                            </a>
                            <a href="#">
                                <img src={dextoolsIcon} alt="Dextools"/>
                            </a>
                        </div>
                        <h4>AI Tax Bot</h4>
                        <h4>2024 All Rights Reserved</h4>
                    </div> */}
                </div>
                <div className="link-wrap">
                <a href="https://t.me/aitaxbot" className="card" target="_blank" rel="noopener noreferrer">
                        <h4>Try AITAXBOT</h4>
                        <h5>+</h5>
                    </a>
                </div>
            </div>
            <div className="blur-wrap">
                <img src={isLgScreen ? blurMobileImg : blurImg} alt="Blur"/>
            </div>
        </div>
    </section>
);

export default ContactUs;
