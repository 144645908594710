import React from 'react';

// import heroImg from '../../assets/media/hero-img.png';
import heroImg from '../../assets/media/img1.png';

import herodesktopImg from '../../assets/media/img0.png';

const Hero = ({isMdScreen}) => (
    <section id="hero" className="hero-section">
        <div className="content-wrap">
            <div className="grid-wrap desktop-only">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className="img-wrap mobile">
                <img src={heroImg} alt="Hero"/>
            </div>
            <div className="text-wrap">
                <h1>Start winning on your taxes</h1>
                <div className="lines-wrap">
                    <div className="line">
         
                        <h4>AItaxbot</h4>
                    </div>
                    <div className="line">
                        <h4>Your tax deductions, simplified. Save more, work less with Altaxbot.</h4>
                    </div>
                </div>
            </div>
            <div className="img-wrap desktop">
                <img src={herodesktopImg} alt="Hero"/>
            </div>
            <div className="link-wrap">
            <a href="https://t.me/aitaxbot" className="card" target="_blank" rel="noopener noreferrer">
                    <h4>Start Now</h4>
                    <h5>+</h5>
                </a>
            </div>
        </div>
    </section>
);

export default Hero;
