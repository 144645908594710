import React, {useCallback} from 'react';

import logo from '../../assets/media/logo.png';
import uniswapIcon from '../../assets/media/uniswap-icon.svg';
import dextoolsIcon from '../../assets/media/dextools-icon.svg';
import telegramIcon from '../../assets/media/telegram-header-icon.svg';
import xIcon from '../../assets/media/x-header-icon.svg';
import robotIcon from '../../assets/media/robot-icon.svg';

const Header = ({pageScrollPosition, isHeaderActive, setIsHeaderActive}) => {
    const onNavLinkClick = useCallback((section) => {
        setIsHeaderActive(false);
        window.scrollTo({
            top: document.getElementById(section).offsetTop - 100,
            behavior: 'smooth'
        });
    }, [setIsHeaderActive]);

    return (
        <header
            className={`${isHeaderActive ? 'active-header' : ''} ${pageScrollPosition > 80 ? 'scroll-header' : ''}`}>
            <div className="logo-wrap">
                <img src={logo} alt="Logo"/>
            </div>
            <div className="toggle-wrap mobile-only">
                <div className="btn-toggle" onClick={() => setIsHeaderActive(!isHeaderActive)}>
                    <span className="text">
                        <p>Menu</p>
                    </span>
                    <span className="icons">
                        <span className="icon"></span>
                        <span className="icon"></span>
                    </span>
                </div>
            </div>
            <div className="nav-wrap">
                <div className="nav-links-wrap">
                    <a onClick={() => onNavLinkClick('about')}><p>Tax Bot</p></a>
                    <a onClick={() => onNavLinkClick('calc')}><p>RevShare</p></a>
                    {/* <a onClick={() => onNavLinkClick('start')}><p>Deductables</p></a> */}
                    <a onClick={() => onNavLinkClick('how-it-works')}><p>Tools</p></a>
                    <a onClick={() => onNavLinkClick('start')}><p>Get Started</p></a>
                </div>
                <div className="actions-wrap">
                    <div className="links-wrap">
                        <a href="https://app.uniswap.org/swap?outputCurrency=0x9f04c2bd696a6191246144ba762456a24c457520" target="_blank" rel="noopener noreferrer">
                            <img src={uniswapIcon} alt="Uniswap"/>
                        </a>
                        <a href="https://www.dextools.io/app/en/ether/pair-explorer/0xe9bbb36d439f7b2be56ce7d426260079a2899502?t=1714587661391" target="_blank" rel="noopener noreferrer">
                            <img src={dextoolsIcon} alt="Dextools"/>
                        </a>
                        <a href="https://t.me/aitaxbotportal" target="_blank" rel="noopener noreferrer">
                            <img src={telegramIcon} alt="Telegram"/>
                        </a>
                        <a href="https://x.com/aitaxbot" target="_blank" rel="noopener noreferrer">
                            <img src={xIcon} alt="X"/>
                        </a>
                    </div>
                    <div className="btn-wrap">
                    <a href="https://app.aitax.bot" className="card" target="_blank" rel="noopener noreferrer"><p>Dashboard</p></a>
                    </div>
                </div>
            </div>
        </header>
    )
};

export default Header;
