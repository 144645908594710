import { Box, Grid, Slider, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

const TaxCalculatorSlider = () => {
  const [value, setValue] = useState(256); // Default position of the slider

  const getTotalValue = (e) => {
    const totalValue = e * 10000; // 1M$ per point on the slider
    return `$${totalValue.toLocaleString()} Saved`; // Add the dollar sign
  };

  const getRevShareProfit = (e) => {
    const totalValue = e * 10000; // Calculate the total revenue
    const tenPercent = totalValue * 0.10; // 10% of the total value
    return tenPercent.toLocaleString();
  };

  return (
    <section id="calc" className="tax-calculator">
      <div className="content-wrap">
        <div className="grid-wrap desktop-only">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="text-wrap">
          <h2 className="animation">RevShare Calculator</h2>
          
          <div className="items-wrap inv_calc_slider">
            <Slider
              aria-label="Restricted values"
              defaultValue={256}
              min={1}
              max={1000} // You can adjust the max value if needed
              valueLabelDisplay="auto"
              valueLabelFormat={(e) => getTotalValue(e)}
              color="#ffff"
              onChange={(e, newValue) => setValue(newValue)} // Update the state when slider is moved
            />
          </div>

          <Grid container mt={4}>
            <Grid item md={12} xs={12} justifyContent="start" flex={1}>
              <Box
                className="item animation"
                justifyContent="center"
                width="100%"
                flex={1}
                display="flex"
              >
                <h4 className="opacity-7">RevShare Profit: $ {getRevShareProfit(value)}</h4>
              </Box>
            </Grid>
          </Grid>
          
          <Grid container mt={7}>
            <Grid item md={8} sm={12} xs={12} justifyContent="start" flex={1}>
              <Stack direction="column" spacing={2} pt={2}>
                <p className="item animation opacity-7">
                The RevShare Calculator lets you estimate how much our users save and 
                the profit from revenue sharing. We charge 10% of the savings, and you 
                can use the slider to see different revenue-sharing scenarios. It shows 
                you how much profit our community can get based on the savings we generate.
                </p>
                <p className="item animation opacity-7">
                  *All cryptocurrency forecasts and predictions presented here are 
                  for informational purposes only and should not be seen as advice or 
                  guidance. They are hypothetical, do not reflect actual results, and are 
                  not guarantees of future outcomes. Consult with a qualified financial 
                  professional before making investment decisions.
                </p>
              </Stack>
            </Grid>

            <Grid item sm={12} xs={12} md={4}>
              <Box pt={2} className="links-wrap animation tax_gap">
                <a
                  href="https://docs.aitax.bot/token/dynamic-revshare"
                  className="card"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    height: "20%",
                  }}
                >
                  <h4>More Info</h4>
                  <h5>+</h5>
                </a>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    </section>
  );
};

export default TaxCalculatorSlider;
