import React from 'react';

// import startImg from '../../assets/media/start-img.png';

import startImg from '../../assets/media/img3.png';

const Start = () => (
    <section id="start" className="start-section">
        <div className="content-wrap">
            <div className="headline-wrap">
                <div className="title-wrap animation">
                    <h2>Get Started<br/> with AITaxBot</h2>
                </div>
                <div className="description-wrap animation">
                    <div className="line">
                
                        <p>
                            1. Enter Your Address: Add your wallet addresses to our bot<br/>
                            2. Estimate Savings: See how much you can save.<br/>
                            3. Get Report: Access your detailed tax report after payment.
                        </p>
                    </div>
                </div>
            </div>
            <div className="steps-wrap">
                <div className="items-wrap">
                    <div className="item animation">
                        <p>Instant Savings Estimation</p>
                    </div>
                    <div className="item animation">
                        <p>Detailed Tax Reports</p>
                    </div>
                    <div className="item animation">
                        <p>Flexible Reporting</p>
                    </div>
                    <div className="item animation">
                        <p>Multichain Support</p>
                    </div>
                    <div className="item animation">
                        <p>Rugged Tokens Report</p>
                    </div>
                </div>
                <div className="img-wrap">
                    <img src={startImg} alt="Start"/>
                </div>
                <div className="items-wrap">
                    <div className="item item-large animation">
                        <p>Fair fees for everyone with a 10% flat fee based on estimated savings.</p>
                    </div>
                    {/* <div className="item animation">
                        <p>Pay only 3-15%.</p>
                    </div> */}
                    <a href="https://t.me/aitaxbot" className="card animation" target="_blank" rel="noopener noreferrer">
                        <h4>Save Now</h4>
                        <h5>+</h5>
                    </a>
                </div>
            </div>
        </div>
    </section>
);

export default Start;
