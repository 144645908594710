import React, { useCallback } from "react";
import logo from "../../assets/media/logo.png";
import telegramIcon from "../../assets/media/telegram-icon.svg";
import xIcon from "../../assets/media/x-icon.svg";
import uniswapIcon from "../../assets/media/uniswap-icon.svg";
import dextoolsIcon from "../../assets/media/dextools-icon.svg";
import robotIcon from '../../assets/media/robot-icon.svg';
import kycImage from '../../assets/media/kyc.png';

function Footer() {
  const onNavLinkClick = useCallback((section) => {
    window.scrollTo({
      top: document.getElementById(section).offsetTop - 100,
      behavior: "smooth",
    });
  }, []);

  return (
    <section className="footer_sec">
      <div className="content-wrap">
        <div className="ftr_inr">
          <div className="col">
            <div className="logo-ftr">
              <img 
                src={logo} 
                alt="Logo" 
                style={{ height: '30px', width: 'auto' }} // Fixed size to avoid pixelation
              />
            </div>
            <p>Simplify tax management and maximize deductions effortlessly.</p>
            <p>Discover smarter tax solutions today!</p><br></br>
            <p>0x9f04c2bd696a6191246144ba762456a24c457520</p>
            
            {/* Add KYC image below the text, centered within the column */}
            <div style={{ textAlign: 'left', marginTop: '10px' }}>
                <a 
                  href="https://assuredefi.com/projects/aitax" // Replace with your desired URL
                  target="_blank" // Opens in a new tab
                  rel="noopener noreferrer" // Security best practice for external links
                >
                  <img 
                    src={kycImage} 
                    alt="KYC Verification" 
                    style={{ height: '80px', borderRadius: '10px', boxShadow: '0px 2px 10px rgba(0,0,0,0.1)' }} // Styling for the image
                  />
                </a>
              </div>
            </div>
          
          <div className="col">
            <div className="ftr_list">
              <ul>
                <li><a onClick={() => onNavLinkClick("about")}>Tax Bot</a></li>
                <li><a onClick={() => onNavLinkClick("calc")}>RevShare</a></li>
                <li><a onClick={() => onNavLinkClick("start")}>Start</a></li>
                <li><a href="https://docs.aitax.bot" target="_blank" rel="noopener noreferrer">Documentation</a></li>
              </ul>
            </div>
          </div>

          <div className="col">
            <div className="socials-wrap2">
              <a href="https://t.me/aitaxbotportal" target="_blank" rel="noopener noreferrer">
                <img src={telegramIcon} alt="Telegram Icon" />
                <h3>Telegram</h3>
              </a>
              <a href="https://x.com/aitaxbot" target="_blank" rel="noopener noreferrer">
                <img src={xIcon} alt="X/Twitter Icon" />
                <h3>X/Twitter</h3>
              </a>
              <a href="https://t.me/aitaxbot" target="_blank" rel="noopener noreferrer">
                <img 
                  src={robotIcon} 
                  alt="Robot Icon" 
                  style={{ width: '44px', height: '44px', filter: 'grayscale(100%) brightness(0)' }} // Consistent size
                />
                <h3>Telegram Bot</h3>
              </a>
            </div>
          </div>
        </div>

        <div className="ftr_btm">
          <div className="links-wrap">
            <a href="https://app.uniswap.org/swap?outputCurrency=0x9f04c2bd696a6191246144ba762456a24c457520" target="_blank" rel="noopener noreferrer">
              <img 
                src={uniswapIcon} 
                alt="Uniswap Icon" 
                style={{ width: '44px', height: '44px' }} // Ensure consistent size
              />
            </a>
            <a href="https://www.dextools.io/app/en/ether/pair-explorer/0xe9bbb36d439f7b2be56ce7d426260079a2899502?t=1714587661391" target="_blank" rel="noopener noreferrer">
              <img 
                src={dextoolsIcon} 
                alt="Dextools Icon" 
                style={{ width: '44px', height: '44px' }} 
              />
            </a>
          </div>
          <h4>contact@aitax.bot</h4>
          <h4>&copy;2024 All Rights Reserved</h4>
        </div>
      </div>
    </section>
  );
}

export default Footer;
