import React, {useState, useMemo, useEffect} from 'react';

// import 'swiper/css';
// import 'swiper/css/pagination';

import Header from '../Header';
import Hero from '../Hero';
import About from '../About';
import HowItWorks from '../HowItWorks';
import Start from '../Start';
import ContactUs from '../ContactUs';
import TaxCalculatorSlider from '../TaxCalculatorSlider/TaxCalculatorSlider';
import Footer from '../Footer/Footer';

const Home = ({windowDimensions}) => {
    const [pageScrollPosition, setPageScrollPosition] = useState(0);
    const [isHeaderActive, setIsHeaderActive] = useState(false);
    const body = document.querySelector('body');
    const {width} = windowDimensions;

    const isLgScreen = useMemo(() => width < 992, [width])
    const isMdScreen = useMemo(() => width < 768, [width])

    const handleScroll = () => {
        const position = window.scrollY;
        setPageScrollPosition(Math.round(position));

        Array.from(document.querySelectorAll('.animation')).forEach((ae) => {
            const {bottom, height} = ae.getBoundingClientRect();
            if (bottom - (height / 2) - window.innerHeight < 0) {
                ae.classList.add('animation-active');
            } else {
                ae.classList.remove('animation-active');
            }
        })
    };

    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = "manual"
        }
    }, []);

    useEffect(() => {
        if (isHeaderActive) {
            body.classList.add('overflow-hidden');
        } else {
            body.classList.remove('overflow-hidden');
        }
    }, [isHeaderActive, body]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {
            passive: true
        });
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <Header
                pageScrollPosition={pageScrollPosition}
                isHeaderActive={isHeaderActive}
                setIsHeaderActive={setIsHeaderActive}
            />
            <main>
                <Hero isMdScreen={isMdScreen}/>
                <About/>
                <TaxCalculatorSlider/>

                <HowItWorks/>
                <Start/>
                <ContactUs isLgScreen={isLgScreen}/>
            </main>
            <Footer/>
        </>
    )
};

export default Home;
